import React from "react";

/**
 * Composant permettant de créer les cards de la partie accompagnement du site numeko
 * @returns {JSX.Element}
 * @constructor
 */
export default function CardStepProject({classStyle = "container-card", children, title, image, alt}) {

  return (
    <div className={classStyle}>
       {
          image ?
             <img src={image} alt={alt} />
             :
             ""
       }
      <h3 dangerouslySetInnerHTML={{__html: title}}/>
        {children}
    </div>
  );
}
